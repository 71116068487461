import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {ChevronsLeft} from '@styled-icons/boxicons-regular/ChevronsLeft'
import {ChevronsRight} from '@styled-icons/boxicons-regular/ChevronsRight'

export const BlogSection = styled.section`
    padding:100px 0px 90px;

    @media ${device.tablet} {
        padding:80px 5px 70px;
    }
`;

export const BlogLayout = styled.div`

`;

export const BlogSingleAnchor = styled.a`
    margin-bottom: 30px;
    display: block;

    :hover{
        text-decoration:none;
    }

    a{
        display: inline-block;
        background: #ffece8;
        color: #ff3d1a;
        font-size: 14px;
        padding: 2px 7px;
        border-radius: 4px;
        margin-bottom: 10px;
    }
    a:hover{
        text-decoration:none;
    }
`;

export const BlogSingleLayout = styled.div`
    figure{
        position: relative;
        overflow: hidden;
    }
    @media ${device.tablet} {
        margin-bottom:40px;
    }

`;

export const DateLayout = styled.div`
    padding: 10px;
    background: #ff3c1c;
    position: absolute;
    right: 0px;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    line-height: 30px;
    bottom: -40px;
    right: 20px;
    margin-bottom:10px;
    text-align:center;

    span{
        line-height: 1;
        display: block;
        color:#fff;
        font-size: 22px;
    }

    p{
        margin-bottom: 0px;
        color: #fff;
        font-family: 'Source Serif Pro', serif;
        line-height: 1;
    }
`;

export const BlogGridImgCol = styled.div`
    position: relative;
    margin-bottom: 20px;

    img{
        width: 100%;
        position: relative;
        height: 210px;
        border-radius: 4px;
    }
`;

export const PaginationWrapper = styled.div`
    /* margin-top: 20px; */

    ul{
        padding:0px;
        margin:0px;
    }
    ul li{
        list-style: none;
        display: inline-block;
        margin-bottom: 0px;
    }
    ul li a {
        padding: 10px 20px;
        border-radius: 4px;
        margin-left: 5px;
        color: #ff3c1c;
        text-decoration:none;
        display: inline-block;
        margin-bottom: 10px;
        background: #fff;
        font-size:16px;
        box-shadow: 0px 0px 10px 0px #d0d0d0;
    }
    ul li a.active {
    color: #fff;
    background: #ff3c1c;
    }
    ul li a:hover {
    color: #fff;
    background: #ff3c1c;
    }
`;

export const LeftIcon = styled(ChevronsLeft)`
    height:20px;
`;
export const RightIcon = styled(ChevronsRight)`
    height:20px;
`;
