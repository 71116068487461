import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {
    BlogSection,
    BlogLayout,
    BlogSingleLayout,
    BlogSingleAnchor,
    BlogGridImgCol,
    DateLayout,
    PaginationWrapper,
    LeftIcon,
    RightIcon
} from './blogs.style';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Blog = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        blogPage2Json{
            Blog{
                BlogDetails{
                    BlogImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    BlogTitle
                    Category
                    BlogDate
                    BlogMonth
                }
            }
        }
    }
  `);
  const BlogData = JSONData.blogPage2Json.Blog;

    return(
        <BlogSection>
            <Container>
                <BlogLayout>
                    <Row>
                    {
                        BlogData.BlogDetails.map((item) => {
                        return <Col md={4}>
                            <BlogSingleLayout>
                                <BlogSingleAnchor href="/">
                                    <BlogGridImgCol>
                                        <figure>
                                            <GatsImg
                                                fluid={item.BlogImage.childImageSharp.fluid}
                                                alt=""
                                            />
                                        </figure>
                                        <DateLayout>
                                            <span>{item.BlogDate}</span>
                                            <p>{item.BlogMonth}</p>
                                        </DateLayout>
                                    </BlogGridImgCol>
                                    <a href="/">{item.Category}</a>
                                    <h5>{item.BlogTitle}</h5>
                                </BlogSingleAnchor>
                            </BlogSingleLayout>
                        </Col>
                        })
                    }
                    </Row>
                </BlogLayout>
                <PaginationWrapper>
                    <ul>
                        <li>
                            <a href="/"><LeftIcon/></a>
                        </li>
                        <li>
                            <a class="active" href="/">1</a>
                        </li>
                        <li>
                            <a href="/">2</a>
                        </li>
                        <li>
                            <a href="/">3</a>
                        </li>
                        <li>
                            <a href="/">4</a>
                        </li>
                        <li>
                            <a href="/"><RightIcon/></a>
                        </li>
                    </ul>
                </PaginationWrapper>
            </Container>
        </BlogSection>
    )
}

export default Blog;
