import React from "react";
import Banner from "../components/CommonBanner";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Footer from "../components/Footer";
import Blogs from "../containers/BlogPage2/Blogs";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImg from '../assets/blog-2-images/banner.jpg';

const BlogListPage = () => (
    <Layout>
        <SEO 
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS />
        <Banner 
            BackgroundImg={BackgroundImg}
            pageTitle="Latest News"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="Latest News"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/blog-grid"
        />
        <Blogs />
        <Footer />
    </Layout>
)

export default BlogListPage;